<template>
    <main-layout itemMenuActive="40" tituloMenu="Consulta Reconocimiento Facial">
      <div class="container">
        <br />
        <form v-on:submit.prevent="handleSubmit">
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <b-field grouped label="Consulta Reconocimiento Facial">
                    <b-field label="">
                      <h1 for="" style="margin-right: 1rem; line-height: 2.5rem">
                        Fecha:
                      </h1>
                      <b-datepicker
                          placeholder="Seleccione fecha..."
                          v-model="fecha"
                          range
                          :max-date="maxDate"
                      >
                      </b-datepicker>
                      <b-button native-type="submit" type="is-info"
                        >Consultar</b-button
                      >
                    </b-field>
                    <div class="uk-navbar-right">
                      <b-field label="">
                        <b-button
                          :disabled="dataTable.length === 0"
                          v-on:click="exportCSV"
                          >CSV</b-button
                        >
                        <b-button v-on:click="exportExcel" :disabled="dataTable.length === 0">Excel</b-button>
                      </b-field>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <TableCard messageEmpty="Sin datos para procesar."
                           :columns="columns" :info="dataTable"
                           detailKey="counter" :tableConfig="tableConfig"
                           :haveFilter="false"></TableCard>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-3">
            <b-field grouped>
              <b-tag type="is-primary" class="control" size="is-medium">Transacciones Exitosas: {{ total_exitosas }}</b-tag>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field grouped>
              <b-tag type="is-warning" class="control" size="is-medium">Transacciones Fallidas: {{ total_fallidas }}</b-tag>
            </b-field>
          </div>
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </main-layout>
  </template>
  
  <script>
  import moment from 'moment';
  import MainLayout from "@/components/Layout/MainLayout";
  import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
  import {COLUMNS_FACE_RECOGNITION_PAGE_TABLE} from "../../assets/config/constants/TableConstants";
  import {formatData, getDateRange} from "../../assets/config/utilities";
  import {saveLog} from "../../services/exportLogs";
  
  export default {
    name: "FrTransactionFinan",
    components: {
      MainLayout,
      TableCard: () => import("../../components/cards/TableCard")
    },
    data() {
      return {
        total_exitosas: 0,
        total_fallidas: 0,
        isLoading: false,
        dataTable: [],
        counter: 0,
        fecha: [],
        fechaInicial: "",
        fechaFinal: "",
        locale: "en-CA",
        minDate: new Date(),
        maxDate: new Date(),
        tableConfig: {
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: false,
        },
        columns: COLUMNS_FACE_RECOGNITION_PAGE_TABLE,
      };
    },
    computed: {
      ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
    },
    mounted() {
      this.obtenerRangoFecha();
      this.$emit("tituloTransaccion", "VU Face Recogn");
      this.nombreTituloMenu("Face Recognition");
    },
    methods: {
      obtenerRangoFecha() {
        const options = {
          method: "get",
          uri: "boards/getRangeDate",
        };
        this._axios(options)
          .then((response) => {
            this.minDate = new Date(response.data[0]);
            this.maxDate = new Date(response.data[1]);
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
          //this.handleSubmit();
      },
      handleSubmit() {
        if (this._PERFIL_MODULO_.facerecognition) {
          this.isLoading = true;
          if (this.fecha == null) {
            this.alertCustomError("Debe seleccionar un mes");
            return;
          }
          if(this.fecha.length === 0){
            this.fechaInicial = moment().startOf('month').format('YYYY-MM-DD hh:mm');
            this.fechaFinal = moment().endOf('month').format('YYYY-MM-DD hh:mm');
          }else{
            const dateRange = getDateRange(this.fecha);
            this.fechaInicial = dateRange[0];
            this.fechaFinal = dateRange[1];
          }
          this.dataTable = [];
          this.counter = 0;
          const options = {
            method: "post",
            uri: "finan/Face",
            data: {
              Fecha: new Date(),
              FechaInicial: this.fecha.length === 0 ? "0001-01-01" : this.fechaInicial,
              FechaFinal: this.fecha.length === 0 ? "0001-01-01" : this.fechaFinal,
            },
          };
          this._axios(options)
            .then((response) => {
              response.data.datos.forEach((item) => {
                this.counter++;
                item.counter = this.counter;
                this.dataTable.push(item);
              });
              this.dataTable = formatData(this.dataTable);
              this.total_exitosas = response.data.cant_exitosas;
              this.total_fallidas = response.data.cant_fallidas;
            })
            .catch((error) => {
              this.isLoadingTable = false;
              console.log(error);
            })
            .finally(() => this.cancelarLoading());
        } else {
          this.$router.push("login");
        }
      },
      exportExcel: function () {
      this.exportFile("exportExcelFaceFinan");
      this.saveExportEvent("excel")
      },
      exportCSV() {
        this.exportFile("exportCSVFaceFinan");
          this.saveExportEvent("csv")
      },
      exportFile(type) {
        if (this.fechaInicial !== "") {
          let fechaInicial = this.convertFecha(this.fechaInicial);
          let fechaFinal = this.convertFecha(this.fechaFinal);
          let routeData = `${this._HOST_SERVE}/finan/${type}/${fechaInicial}/${fechaFinal}`;
          window.open(routeData, "_blank");
        }
      },
      convertFecha: function (fecha) {
        let date = new Date(fecha).toISOString().slice(0, 10);
        return date;
      },
      alertCustomError(msj, titulo = "Error", tipo = "is-danger") {
        this.$buefy.dialog.alert({
          title: titulo,
          message: msj,
          type: tipo,
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      cancelarLoading() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      },
        saveExportEvent(type) {
            saveLog({
                "user": this.getUsuario(),
                "sectionId": 2,
                "reportId": 3,
                "format": type,
                "startDate": this.fecha[0],
                "endDate": this.fecha[1]
            })
        },
      ...mapMutations(["nombreTituloMenu"]),
      ...mapActions(["_axios"]),
        ...mapGetters(["getUsuario"])
    },
  };
  </script>
  
  <style>
  :root {
    --primary: #00522a;
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: #00522a;
    color: #fff;
  }
  
  .pagination-link.is-current {
    background-color: #00522a;
    border-color: #00522a;
    color: #fff;
  }
  
  .tag:not(body).is-primary {
    background-color: #00522a;
    color: #fff;
  }
  .has-text-primary {
    color: #00522a !important;
  }
  .styled-select select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: #00522a;
  }
  .select select:focus,
  .taginput .taginput-container.is-focusable:focus,
  .textarea:focus,
  .input:focus,
  .select select.is-focused,
  .taginput .is-focused.taginput-container.is-focusable,
  .is-focused.textarea,
  .is-focused.input,
  .select select:active,
  .taginput .taginput-container.is-focusable:active,
  .textarea:active,
  .input:active,
  .select select.is-active,
  .taginput .is-active.taginput-container.is-focusable,
  .is-active.textarea,
  .is-active.input {
    border-color: #00522a;
  }
  .datepicker .dropdown .input[readonly]:focus,
  .datepicker .dropdown .input[readonly].is-focused,
  .datepicker .dropdown .input[readonly]:active,
  .datepicker .dropdown .input[readonly].is-active,
  .datepicker .dropdown-trigger .input[readonly]:focus,
  .datepicker .dropdown-trigger .input[readonly].is-focused,
  .datepicker .dropdown-trigger .input[readonly]:active,
  .datepicker .dropdown-trigger .input[readonly].is-active {
    box-shadow: none;
  }
  
  .pagination-previous:focus,
  .pagination-next:focus,
  .pagination-link:focus {
    border-color: #00522a;
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
    border: solid 1px rgba(0, 82, 42, 1);
  }
  </style>